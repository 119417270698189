<app-dashboard-sidemenu></app-dashboard-sidemenu>

<!-- Start Main Content Wrapper Area -->
<div class="main-content d-flex flex-column">

    <app-dashboard-navbar></app-dashboard-navbar>

    <!-- Breadcrumb Area -->
    <!-- <div class="send-email-checker2"> -->
    <div class="breadcrumb-area" *ngFor="let Content of breadcrumb;">
        <h1>{{Content.title}}</h1>
        <ol class="breadcrumb">
            <li class="item"><a routerLink="/">Home</a></li>
            <li class="item"><a routerLink="/dashboard">{{Content.subTitle}}</a></li>
            <li class="item">{{Content.title}}</li>
        </ol>
    </div>
    <!-- </div> -->
    <!-- End Breadcrumb Area -->

    <!-- Start -->
    <div class="card finance-page">
        <p-table #dt2 [value]="jdedata" dataKey="supplierId" [rows]="10" responsiveLayout="scroll"
            [showCurrentPageReport]="true" [rowsPerPageOptions]="[10,20,30,50]" [paginator]="true"
            currentPageReportTemplate="Showing {first} to
            {last} of {totalRecords} entries" [filterDelay]="0" [globalFilterFields]="['orderCo','orderNumber','orderType','lineNumber','orderSuffix',
            'businessUnit' , 'addressNumber','shipToName','billToName']">
            <ng-template pTemplate="caption">
                <span class="p-input-icon-left d-flex align-items-center">
                    <i class="pi pi-search"></i>
                    <input pInputText type="text" (input)="dt2.filterGlobal($event.target.value,
                        'contains')" placeholder="Global Search" [(ngModel)]="filterval" />
                    <p-button type="button" class="ml-2" (click)="reset(dt2)" icon="bx bx-reset"></p-button>
                    <button type="button" pButton pRipple icon="pi pi-file-excel" (click)="onXLSExport()"
                        class="p-button-success mr-2" pTooltip="XLS" tooltipPosition="bottom"
                        style="margin-left: 8px;"></button>
              
                        <div class="add-listings-btn" *ngIf="userroleName.roleName == 'Supplier'||userroleName.roleName =='Admin'">
    
                            <button type="submit" class="submit_btn" (click)="sendSelectedData()">
                              Generate ASN
                            </button>
                        </div>
                </span>
            </ng-template>

            <ng-template pTemplate="header">
                <tr>
                    <th>S.No.</th>
                    <th></th>
                    <!-- <th style="text-align: center ;">Status</th> -->
                    <th>Order Co</th>
                    <th>Order Number</th>
                    <th>Order Type</th>
                    <th>Order Suffix</th>
                    <th>Line Number</th>
                    <th>Business Unit</th>
                    <th>Address Number</th>
                    <th>Bill To Name</th>
                    <th>Ship To Number</th>
                    <th>Ship to Name</th>
                    <th>Order Date</th>
                    <th>Requested Date</th>
                    <th>Scheduled Pick Date</th>
                    <!-- <th>Cancel Date</th> -->
                    <th>Short Item Number</th>
                    <th>Item Number</th>
                    <th>Description 1</th>
                    <th>Description 2</th>
                    <th>Line Type</th>
                    <th>Next Status</th>
                    <th>Next Status Desc</th>
                    <th>Last Status</th>
                    <th>Last Status Desc</th>
                    <th>Value Stream</th>
                    <th>Value Stream Desc</th>
                    <th>Value Stream 1</th>
                    <th>Value Stream 2</th>
                    <th>Unit Of Measure</th>
                    <th>Qunatity Ordered</th>
                    <th>Qunatity Open</th>
                    <th>Unit Price</th>
                    <th>Extended Price</th>
                    <th>Payment Term</th>
                    <th>Invoice Number</th>
                    <th>Buyer Number</th>
                    <th>GL Offset</th>
                    <th>Currency</th>
                    <th>Exchange Rate</th>
                    <th>Foreign Unit Cost</th>
                    <th>Foreign Extended Price</th>
                    <th>Entry Date</th>
                    <th>Order Line Status</th>
                    <th>Date Updated</th>
                </tr>
                <tr>
                    <td></td>
                    <!-- <td style="padding: 4px;"><input pInputText type="text" [ngModel]="orderCo" (input)="dt2.filterGlobal($event.target.value,
                       'orderCo', 'contains')" placeholder="Keyword" /></td> -->
                       <td></td>
                    <td>
                        <input pInputText type="text" (input)="dt2.filter($event.target.value,
                      'orderCo',  'contains')" placeholder="Keyword" [(ngModel)]="orderCo" />
                    </td>
                    
                    <td style="padding: 4px;"><input pInputText type="text" placeholder="Keyword" (input)="dt2.filter($event.target.value,
                      'orderNumber',  'contains')" [(ngModel)]="orderNumber" /></td>
                    <td style="padding: 4px;"><input pInputText type="text" placeholder="Keyword" (input)="dt2.filter($event.target.value,
                      'orderType',  'contains')" [(ngModel)]="orderType" /></td>
                    <td style="padding: 4px;"><input pInputText type="text" placeholder="Keyword" (input)="dt2.filter($event.target.value,
                      'orderSuffix',  'contains')" [(ngModel)]="orderSuffix" /></td>
                    <td style="padding: 4px;"><input pInputText type="text" placeholder="Keyword" (input)="dt2.filter($event.target.value,
                      'lineNumber',  'contains')" [(ngModel)]="lineNumber" /></td>
                    <td style="padding: 4px;"><input pInputText type="text" placeholder="Keyword" (input)="dt2.filter($event.target.value,
                      'businessUnit',  'contains')" [(ngModel)]="businessUnit" /></td>

                    <td style="padding: 4px;"><input pInputText type="text" placeholder="Keyword" (input)="dt2.filter($event.target.value,
                      'addressNumber',  'contains')" [(ngModel)]="addressNumber" /></td>
                    <td style="padding: 4px;"><input pInputText type="text" placeholder="Keyword" (input)="dt2.filter($event.target.value,
                      'billToName',  'contains')" [(ngModel)]="billToName" /></td>
                    <td style="padding: 4px;"><input pInputText type="text" placeholder="Keyword" (input)="dt2.filter($event.target.value,
                      'shipToNumber',  'contains')" [(ngModel)]="shipToNumber" /></td>
                    <td style="padding: 4px;"><input pInputText type="text" placeholder="Keyword" (input)="dt2.filter($event.target.value,
                      'shipToName',  'contains')" [(ngModel)]="shipToName" /></td>
                    <td style="padding: 4px;"><input pInputText type="text" placeholder="Keyword" (input)="dt2.filter($event.target.value,
                      'orderDate',  'contains')" [(ngModel)]="orderDate" /></td>
                    <td style="padding: 4px;"><input pInputText type="text" placeholder="Keyword" (input)="dt2.filter($event.target.value,
                      'requestedDate',  'contains')" [(ngModel)]="requestedDate" /></td>
                    <td style="padding: 4px;"><input pInputText type="text" placeholder="Keyword" (input)="dt2.filter($event.target.value,
                      'scheduledPickDate',  'contains')" [(ngModel)]="scheduledPickDate" /></td>
                 
                    <td style="padding: 4px;"><input pInputText type="text" placeholder="Keyword" (input)="dt2.filter($event.target.value,
                      'shortItemNo',  'contains')" [(ngModel)]="shortItemNumber" /></td>
                    <td style="padding: 4px;"><input pInputText type="text" placeholder="Keyword" (input)="dt2.filter($event.target.value,
                      'itemNumber',  'contains')" [(ngModel)]="itemNumber" /></td>
                    <td style="padding: 4px;"><input pInputText type="text" placeholder="Keyword" (input)="dt2.filter($event.target.value,
                      'itemDesc',  'contains')" [(ngModel)]="description1" /></td>
                    <td style="padding: 4px;"><input pInputText type="text" placeholder="Keyword" (input)="dt2.filter($event.target.value,
                      'itemDesc2',  'contains')" [(ngModel)]="description2" /></td>
                    <td style="padding: 4px;"><input pInputText type="text" placeholder="Keyword" (input)="dt2.filter($event.target.value,
                      'lineType',  'contains')" [(ngModel)]="lineType" /></td>
                    <td style="padding: 4px;"><input pInputText type="text" placeholder="Keyword" (input)="dt2.filter($event.target.value,
                      'nextStatus',  'contains')" [(ngModel)]="nextStatus" /></td>
                    <td style="padding: 4px;"><input pInputText type="text" placeholder="Keyword" (input)="dt2.filter($event.target.value,
                      'nextStatusDesc',  'contains')" [(ngModel)]="nextStatusDesc" /></td>
                    <td style="padding: 4px;"><input pInputText type="text" placeholder="Keyword" (input)="dt2.filter($event.target.value,
                      'lastStatus',  'contains')" [(ngModel)]="lastStatus" /></td>

                    <td style="padding: 4px;"><input pInputText type="text" placeholder="Keyword" (input)="dt2.filter($event.target.value,
                      'lastStatusDesc',  'contains')" [(ngModel)]="lastStatusDesc" /></td>
                    <td style="padding: 4px;"><input pInputText type="text" placeholder="Keyword" (input)="dt2.filter($event.target.value,
                      'valueStream',  'contains')" [(ngModel)]="valueStream" /></td>
                    <td style="padding: 4px;"><input pInputText type="text" placeholder="Keyword" (input)="dt2.filter($event.target.value,
                      'valueStreamDesc',  'contains')" [(ngModel)]="valueStreamDesc" /></td>
                    <td style="padding: 4px;"><input pInputText type="text" placeholder="Keyword" (input)="dt2.filter($event.target.value,
                      'valueStream1',  'contains')" [(ngModel)]="valueStream1" /></td>
                    <td style="padding: 4px;"><input pInputText type="text" placeholder="Keyword" (input)="dt2.filter($event.target.value,
                      'valueStream2',  'contains')" [(ngModel)]="valueStream2" /></td>
                    <td style="padding: 4px;"><input pInputText type="text" placeholder="Keyword" (input)="dt2.filter($event.target.value,
                      'um',  'contains')" [(ngModel)]="unitOfMeasure" /></td>
                    <td style="padding: 4px;"><input pInputText type="text" placeholder="Keyword" (input)="dt2.filter($event.target.value,
                      'quantityOrdered',  'contains')" [(ngModel)]="quantityOrdered" /></td>

                    <td style="padding: 4px;"><input pInputText type="text" placeholder="Keyword" (input)="dt2.filter($event.target.value,
                      'quantityOpen',  'contains')" [(ngModel)]="quantityOpen" /></td>


                    <td style="padding: 4px;"><input pInputText type="text" placeholder="Keyword" (input)="dt2.filter($event.target.value,
                      'unitPrice',  'contains')" [(ngModel)]="unitPrice" /></td>
                    <td style="padding: 4px;"><input pInputText type="text" placeholder="Keyword" (input)="dt2.filter($event.target.value,
                      'extPrice',  'contains')" [(ngModel)]="extendedPrice" /></td>
                    <td style="padding: 4px;"><input pInputText type="text" placeholder="Keyword" (input)="dt2.filter($event.target.value,
                      'paymentTerm',  'contains')" [(ngModel)]="paymentTerm" /></td>
                    <td style="padding: 4px;"><input pInputText type="text" placeholder="Keyword" (input)="dt2.filter($event.target.value,
                      'invoiceNumber',  'contains')" [(ngModel)]="invoiceNumber" /></td>
                    <td style="padding: 4px;"><input pInputText type="text" placeholder="Keyword" (input)="dt2.filter($event.target.value,
                      'buyerNumber',  'contains')" [(ngModel)]="buyerNumber" /></td>
                    <td style="padding: 4px;"><input pInputText type="text" placeholder="Keyword" (input)="dt2.filter($event.target.value,
                      'glOffset',  'contains')" [(ngModel)]="glOffset" /></td>
                    <td style="padding: 4px;"><input pInputText type="text"  [(ngModel)]="currency"  (input)="dt2.filter($event.target.value,
                      'currency',  'contains')" placeholder="Keyword"  /></td>
                    <td style="padding: 4px;"><input pInputText type="text" placeholder="Keyword" (input)="dt2.filter($event.target.value,
                      'exchangeRate',  'contains')" [(ngModel)]="exchangeRate" /></td>
                    <td style="padding: 4px;"><input pInputText type="text" placeholder="Keyword" (input)="dt2.filter($event.target.value,
                      'foreignUnitCost',  'contains')" [(ngModel)]="foreignUnitCost" /></td>
                    <td style="padding: 4px;"><input pInputText type="text" placeholder="Keyword" (input)="dt2.filter($event.target.value,
                      'foreignExtPrice',  'contains')" [(ngModel)]="foreignExtendedPrice" /></td>
                    <td style="padding: 4px;"><input pInputText type="text" placeholder="Keyword" (input)="dt2.filter($event.target.value,
                      'entyDate',  'contains')" [(ngModel)]="entyDate" /></td>
                    <td style="padding: 4px;"><input pInputText type="text" placeholder="Keyword" (input)="dt2.filter($event.target.value,
                      'orderLineStatus',  'contains')" [(ngModel)]="orderLineStatus" /></td>
                    <td style="padding: 4px;"><input pInputText type="text" placeholder="Keyword" (input)="dt2.filter($event.target.value,
                      'dateUpdated',  'contains')" [(ngModel)]="dateUpdated" /></td>

                </tr>
            </ng-template>

            <ng-template pTemplate="body" let-jde let-i="rowIndex">
                <tr class="p-selectable-row">
                    <td>{{i+1}}</td>
                    <td>
                    <input type="checkbox" [(ngModel)]="jde.selected" ></td>
                    <!-- <td class="status">
                      <span class="approved" *ngIf="jde.orderCo">
                        Line Closed
                        </span>
                        <span class="inprogress" *ngIf="jde.OrderCo">
                          ASN Pending
                          </span> 
                     
                      </td> -->
                    <td>{{jde.orderCo ? jde.orderCo : '-'}}</td>
                    <td>{{jde.orderNumber ? jde.orderNumber : '-'}}</td>
                    <td>{{jde.orderType ? jde.orderType : '-'}}</td>
                    <td>{{jde.orderSuffix ? jde.orderSuffix : '-'}}</td>
                    <td>{{jde.lineNumber ? jde.lineNumber : '-'}}</td>
                    <td>{{jde.businessUnit ? jde.businessUnit : '-'}}</td>
                    <td>{{jde.addressNumber ? jde.addressNumber : '-'}}</td>
                    <td>{{jde.billToName ? jde.billToName : '-'}}</td>
                    <td>{{jde.shipToNumber ? jde.shipToNumber : '-'}}</td>
                    <td>{{jde.shipToName ? jde.shipToName : '-'}}</td>
                    <td>{{jde.orderDate ? (jde.orderDate | date: 'dd/MM/yyyy') : '-'}}</td>
                    <td>{{jde.requestedDate ? (jde.requestedDate | date: 'dd/MM/yyyy') : '-'}}</td>
                    <td>{{jde.schPickDate ?(jde.schPickDate | date: 'dd/MM/yyyy'): '-'}}</td>
                    <td>{{jde.shortItemNo ? jde.shortItemNo : '-'}}</td>
                    <td>{{jde.itemNumber ? jde.itemNumber : '-'}}</td>
                    <td>{{jde.itemDesc ? jde.itemDesc : '-'}}</td>
                    <td>{{jde.itemDesc2 ? jde.itemDesc2 : '-'}}</td>
                    <td>{{jde.lineType ? jde.lineType : '-'}}</td>
                    <td>{{jde.nextStatus ? jde.nextStatus : '-'}}</td>
                    <td>{{jde.nextStatusDesc ? jde.nextStatusDesc : '-'}}</td>
                    <td>{{jde.lastStatus ? jde.lastStatus : '-'}}</td>
                    <td>{{jde.lastStatusDesc ? jde.lastStatusDesc : '-'}}</td>
                    <td>{{jde.valueStream ? jde.valueStream : '-'}}</td>
                    <td>{{jde.valueStreamDesc ? jde.valueStreamDesc : '-'}}</td>
                    <td>{{jde.valueStream1 ? jde.valueStream1 : '-'}}</td>
                    <td>{{jde.valueStream2 ? jde.valueStream2 : '-'}}</td>
                    <td>{{jde.um ? jde.um : '-'}}</td>
                    <td>{{jde.quantityOrdered ? jde.quantityOrdered : '-'}}</td>
                    <td>{{jde.quantityOpen ? jde.quantityOpen : '-'}}</td>
                    <td>{{jde.unitPrice ? jde.unitPrice : '-'}}</td>
                    <td>{{jde.extPrice ? jde.extPrice : '-'}}</td>
                    <td>{{jde.paymentTerms ? jde.paymentTerms : '-'}}</td>
                    <td>{{jde.invoiceNumber ? jde.invoiceNumber : '-'}}</td>
                    <td>{{jde.buyerNumber ? jde.buyerNumber : '-'}}</td>
                    <td>{{jde.glOffset ? jde.glOffset : '-'}}</td>
                    <td>{{jde.currency ? jde.currency : '-'}}</td>
                    <td>{{ isExchangeRateValid(jde.exchangeRate) }}</td>
                    <td>{{jde.foreignUnitCost }}</td>
                    <td>{{jde.foreignExtPrice }}</td>
                    <td>{{ jde.entyDate ? (jde.entyDate | date: 'dd/MM/yyyy') : '-' }}</td>
                    <td>{{jde.orderLineStatus ? jde.orderLineStatus : '-'}}</td>
                    <td>{{jde.dateUpdated ? (jde.dateUpdated | date: 'dd/MM/yyyy') : '-'}}</td>
                </tr>
            </ng-template>

            <ng-template pTemplate="emptymessage">
                <tr>
                    <td colspan="5">No jdes found.</td>
                </tr>
            </ng-template>
        </p-table>
        <div class="flex-grow-1"></div>
    </div>
    <!-- End -->

    <app-copyrights></app-copyrights>

</div>