import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { SelectDropDownModule } from 'ngx-select-dropdown';
import { NgxTypedJsModule } from 'ngx-typed-js';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgxPaginationModule } from 'ngx-pagination';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { FooterStyleOneComponent } from './components/common/footer-style-one/footer-style-one.component';
import { NavbarStyleOneComponent } from './components/common/navbar-style-one/navbar-style-one.component';
import { NavbarStyleTwoComponent } from './components/common/navbar-style-two/navbar-style-two.component';
import { NotFoundComponent } from './components/pages/not-found/not-found.component';
import { AccordionModule } from 'primeng/accordion';
import { DashboardComponent } from './components/pages/dashboard/dashboard.component';
import { DashboardNavbarComponent } from './components/common/dashboard-navbar/dashboard-navbar.component';
import { CopyrightsComponent } from './components/pages/dashboard/copyrights/copyrights.component';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { TableModule } from 'primeng/table';
import { ToastModule } from 'primeng/toast';
import { CalendarModule } from 'primeng/calendar';
import { SliderModule } from 'primeng/slider';
import { MultiSelectModule } from 'primeng/multiselect';
import { ContextMenuModule } from 'primeng/contextmenu';
import { DialogModule } from 'primeng/dialog';
import { ButtonModule } from 'primeng/button';
import { DropdownModule } from 'primeng/dropdown';
import { ProgressBarModule } from 'primeng/progressbar';
import { InputTextModule } from 'primeng/inputtext';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { ConfirmationService, MessageService } from 'primeng/api';
import { BadgeModule } from 'primeng/badge';
import { MessagesModule } from 'primeng/messages';
import { AuthInterceptor } from './interceptor/auth.interceptor';
import { SidebarModule } from 'primeng/sidebar';
import { CommonFieldsComponent } from './components/custom/common-components/common-fields/common-fields.component';
import { PrimeToastComponent } from './components/custom/common-components/prime-toast/prime-toast.component';
import { ReportsComponent } from './components/custom/common-components/reports/reports.component';
import { LoginComponent } from './components/custom/user-profile/login/login.component';
import { ResetAccountInfoComponent } from './components/custom/user-profile/reset-account-info/reset-account-info.component';
import { DashboardSidemenuComponent } from './components/common/dashboard-sidemenu/dashboard-sidemenu.component';
import { RecentActivitiesComponent } from './components/pages/dashboard/recent-activities/recent-activities.component';
import { StatsComponent } from './components/pages/dashboard/stats/stats.component';
import { GroupbyPipe } from './groupby.pipe';
import { PasswordModule } from 'primeng/password';
import { InputNumberModule } from 'primeng/inputnumber';
import { SplitPipe } from './split.pipe';
import { KeyFilterModule} from 'primeng/keyfilter';
import { ClipboardModule } from '@angular/cdk/clipboard';
import { CarouselModule} from 'primeng/carousel';
import { TitleCasePipe } from '@angular/common';
import { SuccessPageComponent } from './components/custom/common-components/success-page/success-page.component';
import {InputSwitchModule} from 'primeng/inputswitch';
import {RadioButtonModule} from 'primeng/radiobutton';
import { FilterPipe } from './filter.pipe';
import { ProductImageComponent } from './components/custom/common-components/product-image/product-image.component';
import { BUMasterComponent } from './components/custom/supplier-master/bumaster/bumaster.component';
import { BussinessUnitMasterComponent } from './components/custom/supplier-master/bussiness-unit-master/bussiness-unit-master.component';
import { CostBreakerComponent } from './components/custom/supplier-master/cost-breaker/cost-breakup.component';
import { CostComponent } from './components/custom/supplier-master/cost/cost.component';
import { ItemMasterComponent } from './components/custom/supplier-master/item-master/item-master.component';
import { ManufacturingProcessListComponent } from './components/custom/supplier-master/manufacturing-process-list/manufacturing-process-list.component';
import { MaterialConstructionProcessComponent } from './components/custom/supplier-master/material-construction-process/material-construction-process.component';
import { MaterialFormComponent } from './components/custom/supplier-master/material-form/material-form.component';
import { MaterialGradeComponent } from './components/custom/supplier-master/material-grade/material-grade.component';
import { OthercategorymasterComponent } from './components/custom/supplier-master/othercategorymaster/othercategorymaster.component';
import { CustomerBranchPlantMasterComponent } from './components/custom/customer-master/customer-branch-plant-master/customer-branch-plant-master.component';
import { CustomerBussinessMasterComponent } from './components/custom/customer-master/customer-bussiness-master/customer-bussiness-master.component';
import { DocumentMasterComponent } from './components/custom/customer-master/document-master/document-master.component';
import { PaymentTermsComponent } from './components/custom/customer-master/payment-terms/payment-terms.component';
import { SupplierTypeDocumentComponent } from './components/custom/supplier-master/supplier-type-document/supplier-type-document.component';
import { SupplierOnboardingComponent } from './components/custom/supplier-components/supplier-registration-process/supplier-onboarding/supplier-onboarding.component';
import { CustomerChangeRequestListComponent } from './components/custom/customer-component/customer-change-process/customer-change-request-list/customer-change-request-list.component';
import { CustomerChangeRequestLogsComponent } from './components/custom/customer-component/customer-change-process/customer-change-request-logs/customer-change-request-logs.component';
import { CustomerRaiseRequestComponent } from './components/custom/customer-component/customer-change-process/customer-raise-request/customer-raise-request.component';
import { CustomerAuditLogsComponent } from './components/custom/customer-component/customer-registration-process/customer-audit-logs/customer-audit-logs.component';
import { CustomerEditFormComponent } from './components/custom/customer-component/customer-registration-process/customer-edit-form/customer-edit-form.component';
import { CustomerFormComponent } from './components/custom/customer-component/customer-registration-process/customer-form/customer-form.component';
import { CustomerLinkGenerationComponent } from './components/custom/customer-component/customer-registration-process/customer-link-generation/customer-link-generation.component';
import { CustomerListComponent } from './components/custom/customer-component/customer-registration-process/customer-list/customer-list.component';
import { CustomerUploadDocComponent } from './components/custom/customer-component/customer-upload-doc/customer-upload-doc.component';
import { ChangeRequestLogsComponent } from './components/custom/supplier-components/supplier-change-request-process/change-request-logs/change-request-logs.component';
import { SupplierChangeApprovalComponent } from './components/custom/supplier-components/supplier-change-request-process/supplier-change-approval/supplier-change-approval.component';
import { SupplierchangerequestComponent } from './components/custom/supplier-components/supplier-change-request-process/supplierchangerequest/supplierchangerequest.component';
import { ApqpDocumentComponent } from './components/custom/supplier-components/supplier-documents/apqp-document/apqp-document.component';
import { APQPComponent } from './components/custom/supplier-components/supplier-documents/apqp/apqp.component';
import { LoanToolAgreementComponent } from './components/custom/supplier-components/supplier-documents/loan-tool-agreement/loan-tool-agreement.component';
import { OrdersComponent } from './components/custom/supplier-components/supplier-documents/orders/orders.component';
import { PpapComponent } from './components/custom/supplier-components/supplier-documents/ppap/ppap.component';
import { AssessmentDocComponent } from './components/custom/supplier-components/supplier-registration-process/assessment-doc/assessment-doc.component';
import { FinanceComponent } from './components/custom/supplier-components/supplier-registration-process/finance/finance.component';
import { SupplierFormDetailsComponent } from './components/custom/supplier-components/supplier-registration-process/supplier-form-details/supplier-form-details.component';
import { SupplierListComponent } from './components/custom/supplier-components/supplier-registration-process/supplier-list/supplier-list.component';
import { SupplierOnboardingDataComponent } from './components/custom/supplier-components/supplier-registration-process/supplier-onboarding-data/supplier-onboarding-data.component';
import { SuppliersAuditLogsComponent } from './components/custom/supplier-components/supplier-registration-process/suppliers-audit-logs/suppliers-audit-logs.component';
import { UserAccountComponent } from './components/custom/supplier-components/supplier-registration-process/user-account/user-account.component';
import { VerificationFormComponent } from './components/custom/supplier-components/supplier-registration-process/verification-form/verification-form.component';
import { CommonDashboardPopupComponent } from './components/custom/supplier-components/supplier-request-for-quotation-process/common-dashboard-popup/common-dashboard-popup.component';
import { EnquiryComparisonByIdComponent } from './components/custom/supplier-components/supplier-request-for-quotation-process/enquiry-comparison-by-id/enquiry-comparison-by-id.component';
import { EnquiryComparisonComponent } from './components/custom/supplier-components/supplier-request-for-quotation-process/enquiry-comparison/enquiry-comparison.component';
import { RequestForQuotationComponent } from './components/custom/supplier-components/supplier-request-for-quotation-process/request-for-quotation/request-for-quotation.component';
import { SupplierAssignListComponent } from './components/custom/supplier-components/supplier-request-for-quotation-process/supplier-assign-list/supplier-assign-list.component';
import { SupplierAssignComponent } from './components/custom/supplier-components/supplier-request-for-quotation-process/supplier-assign/supplier-assign.component';
import { SupplierUploadDocComponent } from './components/custom/supplier-upload-doc/supplier-upload-doc.component';
import { RegisterUserComponent } from './components/custom/user-profile/register-user/register-user.component';
import { DashboardWalletComponent } from './components/custom/supplier-components/supplier-request-for-quotation-process/dashboard-wallet/dashboard-wallet.component';
import { DashboardAddListingsComponent } from './components/custom/supplier-components/supplier-registration-process/dashboard-add-listings/dashboard-add-listings.component';
import { DashboardMyProfileComponent } from './components/custom/user-profile/dashboard-my-profile/dashboard-my-profile.component';
import { LoginFormComponent } from './components/custom/user-profile/login-form/login-form.component';
import { SupplierOrderDetailsComponent } from './components/custom/supplier-components/supplier-request-for-quotation-process/supplier-order-details/supplier-order-details.component';
import { ToolLoanComponent } from './components/custom/supplier-components/tool-loan/tool-loan.component';
import { PartDrawingComponent } from './components/custom/supplier-master/part-drawing/part-drawing.component';
import { OrderStatusComponent } from './components/custom/customer-component/order-status/order-status.component';
import { JdeDataListingComponent } from './components/custom/supplier-master/jde-data-listing/jde-data-listing.component';
import { AsnGenerationFormComponent } from './components/custom/supplier-master/asn-generation-form/asn-generation-form.component';


@NgModule({
  declarations: [
    AppComponent,
    FooterStyleOneComponent,
    NavbarStyleOneComponent,
    NavbarStyleTwoComponent,
    NotFoundComponent,
    DashboardComponent,
    DashboardNavbarComponent,
    DashboardSidemenuComponent,
    CopyrightsComponent,
    RecentActivitiesComponent,
    DashboardWalletComponent,
    DashboardMyProfileComponent,
    DashboardAddListingsComponent,
    UserAccountComponent,
    ResetAccountInfoComponent,
    VerificationFormComponent,
    SupplierOnboardingComponent,
    ItemMasterComponent,
    CostBreakerComponent,
    PrimeToastComponent,
    RequestForQuotationComponent,
    CostComponent,
    SupplierAssignComponent,
    LoginComponent,
    SupplierListComponent,
    StatsComponent,
    SupplierOnboardingDataComponent,
    RegisterUserComponent,
    EnquiryComparisonComponent,
    ReportsComponent,
    CommonFieldsComponent,
    SupplierAssignListComponent,
    SupplierOrderDetailsComponent,
    APQPComponent,
    PpapComponent,
    ApqpDocumentComponent,
    LoanToolAgreementComponent,
    ManufacturingProcessListComponent,
    MaterialConstructionProcessComponent,
    SupplierFormDetailsComponent,
    GroupbyPipe,
    DocumentMasterComponent,
    SplitPipe,
    CustomerFormComponent,
    SupplierchangerequestComponent,
    EnquiryComparisonByIdComponent,
    OrdersComponent,
    FinanceComponent,
    SupplierChangeApprovalComponent,
    SuccessPageComponent,
    AssessmentDocComponent,
    OthercategorymasterComponent,
    BUMasterComponent,
    BussinessUnitMasterComponent,
    ChangeRequestLogsComponent,
    FilterPipe,
    CustomerListComponent,
    CommonDashboardPopupComponent,
    MaterialGradeComponent,
    MaterialFormComponent,
    SupplierUploadDocComponent,
    CustomerLinkGenerationComponent,
    CustomerEditFormComponent,
    CustomerUploadDocComponent,
    PaymentTermsComponent,
    CustomerAuditLogsComponent,
    SuppliersAuditLogsComponent,
    ProductImageComponent,
    SupplierTypeDocumentComponent,
    CustomerRaiseRequestComponent,
    CustomerChangeRequestListComponent,
    CustomerChangeRequestLogsComponent,
    CustomerBranchPlantMasterComponent,
    CustomerBussinessMasterComponent,
    LoginFormComponent,
    ToolLoanComponent,
    PartDrawingComponent,
    OrderStatusComponent,
    JdeDataListingComponent,
    AsnGenerationFormComponent
  ],
  imports: [
    BrowserModule,
    HttpClientModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    CarouselModule,
    SelectDropDownModule,
    NgxTypedJsModule,
    FormsModule,
    NgxPaginationModule,
    ReactiveFormsModule,
    TableModule,
    CalendarModule,
    SliderModule,
    DialogModule,
    InputSwitchModule,
    MultiSelectModule,
    ContextMenuModule,
    DropdownModule,
    ButtonModule,
    PasswordModule,
    ToastModule,
    RadioButtonModule,
    InputTextModule,
    ProgressBarModule,
    ConfirmDialogModule,
    MessagesModule,
    BadgeModule,
    SidebarModule,
    AccordionModule,
    KeyFilterModule,
    InputNumberModule,
    ClipboardModule
  ],
  providers: [ConfirmationService, MessageService,TitleCasePipe,
  { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true },],
  bootstrap: [AppComponent]
})
export class AppModule { }
