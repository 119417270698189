import { Component, ViewChild } from '@angular/core';
import { Table } from 'primeng/table';
import { ApiServiceService } from 'src/app/api-service.service';
import { AppUtility } from 'src/app/interceptor/appUtitlity';
import { DatePipe } from '@angular/common'
import { Router } from '@angular/router';
@Component({
  selector: 'app-jde-data-listing',
  templateUrl: './jde-data-listing.component.html',
  styleUrls: ['./jde-data-listing.component.scss'],
  providers: [DatePipe]
})
export class JdeDataListingComponent {
  breadcrumb = [
    {
      title: 'JDE data List',
      subTitle: 'Dashboard'
    }
  ]
  
  supplierList: any = [];
  excelData: any[] = [];
  filteredXLSData: any[] = [];
  orderCo: any
  jdedata: any[] = []
  orderLineStatus:any;
  filterval: string
  filteredJdeData: any[];
  entyDate:any;
  orderNumber: any;
  orderType: string;
  dateUpdated :any;
  foreignUnitCost :any;
  exchangeRate :any;
  currency :any;
  glOffset :any;
  buyerNumber :any;
  invoiceNumber :any;
  paymentTerm :any;
  extendedPrice :any;
  unitPrice :any;
  quantityOpen :any;
  quantityOrdered :any;
  unitOfMeasure :any;
  valueStream2 :any;
  valueStream1 :any;
  valueStreamDesc :any;
  valueStream :any;
  lastStatusDesc :any;
  lastStatus :any;
  nextStatusDesc :any;
  nextStatus :any;
  lineType :any;
  description2 :any;
  description1 :any;
  foreignExtendedPrice :any;
  orderSuffix :any;
  lineNumber :any;
  businessUnit :any;
  addressNumber :any;
  billToName :any;
  shipToNumber :any;
  shipToName :any;
  orderDate :any;
  requestedDate :any;
  scheduledPickDate :any;
  cancelDate :any;
  shortItemNumber :any;
  itemNumber :any;
  userroleName: any;

  constructor(private _apiService: ApiServiceService, public _utility: AppUtility, private datePipe: DatePipe,
    private router: Router
  ) { }
  @ViewChild('dt2') dataTable: Table;
  ngOnInit(): void {
    this.userroleName = this._utility.getLocalStorageDetails();
  
  
    this.getdata();
  }


  getdata() {
    this._utility.loader(true);
    this._apiService.getjdedata()
      .then((res: any) => {
        console.log(res);
        if (res.success) {
          this.jdedata = res.returnValue;
          console.log(this.jdedata);
        } else {
          this.jdedata = [];
        }
      })
      .catch((error) => {
         this._apiService.showMessage(error.message, "error");
        this.jdedata = [];
      })
      .finally(() => {
        this._utility.loader(false);
      });
  }
  

  isExchangeRateValid(exchangeRate: any): string | number {
    // Check if exchangeRate is a valid number, return it if true, otherwise return '-'
    return typeof exchangeRate === 'number' ? exchangeRate : '- ';
  }

  reset(dt2) {
    dt2.reset();
    this.filterval = ''
    this.orderCo = ''
    this.orderNumber = ''
    this.orderType = ''
    this.dateUpdated = ''
    this.foreignUnitCost = ''
    this.exchangeRate = ''
    this.currency = ''
    this.glOffset = ''
    this.buyerNumber = ''
    this.invoiceNumber = ''
    this.paymentTerm = ''
    this.extendedPrice = ''
    this.unitPrice = ''
    this.quantityOpen = ''
    this.quantityOrdered = ''
    this.unitOfMeasure = ''
    this.valueStream2 = ''
    this.valueStream1 = ''
    this.valueStreamDesc = ''
    this.valueStream = ''
    this.lastStatusDesc = ''
    this.lastStatus = ''
    this.nextStatusDesc = ''
    this.nextStatus = ''
    this.lineType = ''
    this.description2 = ''
    this.description1 = ''
    this.foreignExtendedPrice = ''
    this.orderSuffix = ''
    this.lineNumber = ''
    this.businessUnit = ''
    this.addressNumber = ''
    this.billToName = ''
    this.shipToNumber = ''
    this.shipToName = ''
    this.orderDate = ''
    this.requestedDate = ''
    this.scheduledPickDate = ''
    this.cancelDate = ''
    this.shortItemNumber = ''
    this.itemNumber = ''
    this.entyDate=''
    this.orderLineStatus=''
  }
  onXLSExport() {
    let filteredBy

    for (let i = 0; i < this.jdedata.length; i++) {

      this.excelData.push({
        'S.No.': i + 1,
        'Order Co': this.jdedata[i]?.orderCo ?? '-',
        'Order Number': this.jdedata[i]?.orderNumber ?? '-',
        'Order Type': this.jdedata[i]?.orderType ?? '-',
        'Order Suffix': this.jdedata[i]?.orderSuffix ?? '-',
        'Line Number': this.jdedata[i]?.lineNumber ?? '-',
        'Business Unit': this.jdedata[i]?.businessUnit ?? '-',
        'Address Number': this.jdedata[i]?.addressNumber ?? '-',
        'Bill To Name': this.jdedata[i]?.billToName ?? '-',
        'Ship To Number': this.jdedata[i]?.shipToNumber ?? '-',
        'Ship to Name': this.jdedata[i]?.shipToName ?? '-',
        'Order Date': this.datePipe.transform(this.jdedata[i]?.orderDate, 'MM/dd/yyyy') ?? '-',
        'Requested Date': this.datePipe.transform(this.jdedata[i]?.requestedDate, 'MM/dd/yyyy') ?? '-',
        'Scheduled Pick Date': this.datePipe.transform(this.jdedata[i]?.scheduledPickDate, 'MM/dd/yyyy') ?? '-',
        'Cancel Date': this.datePipe.transform(this.jdedata[i]?.cancelDate, 'MM/dd/yyyy') ?? '-',
        'Short Item Number': this.jdedata[i]?.shortItemNo ?? '-',
        'Item Number': this.jdedata[i]?.itemNumber ?? '-',
        'Description 1': this.jdedata[i]?.itemDesc ?? '-',
        'Description 2': this.jdedata[i]?.itemDesc2 ?? '-',
        'Line Type': this.jdedata[i]?.lineType ?? '-',
        'Next Status': this.jdedata[i]?.nextStatus ?? '-',
        'Next Status Desc': this.jdedata[i]?.nextStatusDesc ?? '-',
        'Last Status': this.jdedata[i]?.lastStatus ?? '-',
        'Last Status Desc': this.jdedata[i]?.lastStatusDesc ?? '-',
        'Value Stream': this.jdedata[i]?.valueStream ?? '-',
        'Value Stream Desc': this.jdedata[i]?.valueStreamDesc ?? '-',
        'Value Stream 1': this.jdedata[i]?.valueStream1 ?? '-',
        'Value Stream 2': this.jdedata[i]?.valueStream2 ?? '-',
        'Unit Of Measure': this.jdedata[i]?.um?? '-',
        'Quantity Ordered': this.jdedata[i]?.quantityOrdered ?? '-',
        'Quantity Open': this.jdedata[i]?.quantityOpen ?? '-',
        'Unit Price': this.jdedata[i]?.unitPrice ?? '-',
        'Extended Price': this.jdedata[i]?.extendedPrice ?? '-',
        'Payment Term': this.jdedata[i]?.paymentTerm ?? '-',
        'Invoice Number': this.jdedata[i]?.invoiceNumber ?? '-',
        'Buyer Number': this.jdedata[i]?.buyerNumber ?? '-',
        'GL Offset': this.jdedata[i]?.glOffset ?? '-',
        'Currency': this.jdedata[i]?.currency ?? '-',
        'Exchange Rate': this.jdedata[i]?.exchangeRate ?? '-',
        'Foreign Unit Cost': this.jdedata[i]?.foreignUnitCost ?? '-',
        'Foreign Extended Price': this.jdedata[i]?.foreignExtendedPrice ?? '-',
        'Entry Date': this.datePipe.transform(this.jdedata[i]?.entyDate, 'MM/dd/yyyy') ?? '-',
        'Order Line Status': this.jdedata[i]?.orderLineStatus ?? '-',
        'Date Updated': this.datePipe.transform(this.jdedata[i]?.dateUpdated, 'MM/dd/yyyy') ?? '-'
      });


    }
    this._apiService.exportExcel(this.excelData, 'JDE-list', filteredBy);
  }
  sendSelectedData() {
    const selectedData = this.jdedata.filter(item => item.selected);
    console.log(selectedData);
    
    if (selectedData.length > 0) {
      // Navigate to the new page and pass selected data
      this._apiService.setSelectedData(selectedData); 
      this.router.navigate(['/asn-generation']);
    } else {
      this._apiService.showMessage('please select atleast one checkbox','error');

    }
  }
}
